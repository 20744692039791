<template>
    <div class="vx-card p-6">
        <h4>Chi tiết Service AI</h4>
        <div class="vx-row w-full mt-4">
            <div class="vx-col md:w-3/12 w-full">
                <label class="vs-input--label">ID</label>
                <span class="text-danger">*</span>
                <vs-input class="w-full" v-model="serviceInfo.id" v-validate="'required'" name="ID" :disabled="true">
                </vs-input>
                <span class="text-danger text-sm">{{ errors.first('ID') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
                <label class="vs-input--label">Name</label>
                <span class="text-danger">*</span>
                <vs-input class="w-full" v-model="serviceInfo.name" v-validate="'required'" name="Tên Service"
                    :disabled="true"></vs-input>
                <span class="text-danger text-sm">{{ errors.first('Tên Service') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
                <label class="vs-input--label">IP</label>
                <span class="text-danger">*</span>
                <vs-input class="w-full" v-model="serviceInfo.ip" v-validate="'required'" name="IP" :disabled="true">
                </vs-input>
                <span class="text-danger text-sm">{{ errors.first('IP') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
                <label class="vs-input--label">Port</label>
                <span class="text-danger">*</span>
                <vs-input class="w-full" v-model="serviceInfo.apiPort" v-validate="'required'" name="Port"
                    :disabled="true"></vs-input>
                <span class="text-danger text-sm">{{ errors.first('Port') }}</span>
            </div>
        </div>
        <div class="vx-row w-full mt-4">
            <div class="vx-col md:w-1/4 w-full">
                <label class="vs-input--label">IKey</label>
                <span class="text-danger">*</span>
                <vs-input class="w-full" v-model="serviceInfo.ikey" v-validate="'required'" name="Ikey"
                    :disabled="true"></vs-input>
                <span class="text-danger text-sm">{{ errors.first('Ikey') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
                <label class="vs-input--label">Domain</label>
                <span class="text-danger">*</span>
                <vs-input class="w-full" v-model="serviceInfo.domain" v-validate="'required'" name="Domain"></vs-input>
                <span class="text-danger text-sm">{{ errors.first('Domain') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
                <label class="vs-input--label">Số Camera tối đa</label>
                <span class="text-danger">*</span>
                <vs-input class="w-full" v-model="serviceInfo.maximumCamera" v-validate="'required|numeric'"
                    name="Số Camera tối đa"></vs-input>
                <span class="text-danger text-sm">{{ errors.first('Số Camera tối đa') }}</span>
            </div>
            <div class="vx-col md:w-1/4 w-full">
                <label class="vs-input--label">Trạng thái</label>
                <span class="text-danger">*</span>
                <v-select class="w-full" v-model="serviceInfo.status" :options="statusServiceOption" v-validate="'required'"
                    :clearable="false" name="Trạng thái">
                </v-select>
                <span class="text-danger text-sm">{{ errors.first('Trạng thái') }}</span>
            </div>
        </div>
        <div class="vx-row">
            <div class="vx-col w-full">
                <div class="mt-8 flex flex-wrap items-center justify-end">
                    <vs-button class="ml-auto mt-2" @click="saveChange" :disabled="!validateForm">
                        {{ $t('button.update') }}
                    </vs-button>
                    <vs-button class="ml-4 mt-2" type="border" color="warning" @click="goBack">
                        {{ $t('button.back') }}
                    </vs-button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import vSelect from 'vue-select';

export default {
    data() {
        return {
            serviceInfo: {},
            statusServiceOption: [
                { value: 0, label: this.$t('root.stopWorking') },
                { value: 1, label: this.$t('root.active') }
            ],
        }
    },

    components: {
        vSelect,
    },

    computed: {
        validateForm() {
            return !this.errors.any()
        },

        statusService: {
            get() {
                let obj = this.statusServiceOption.find(x => x.value == this.serviceInfo.status)
                if (!obj) {
                    return;
                }
                return {
                    label: obj.label,
                    value: obj.value
                }
            },
            set(obj) {
                this.serviceInfo.status = obj.value
            }
        }
    },

    created() {
        this.findServiceAIById()
    },

    methods: {

        findServiceAIById(){
            this.$vs.loading()
            let serviceId = this.$route.query.id
            this.$crm.get(`ai-service/find-by-id/${serviceId}`).then(response => {
                this.$vs.loading.close()
                this.serviceInfo = {
                    apiPort: response.data.apiPort,
                    domain: response.data.domain,
                    id: response.data.id,
                    ikey: response.data.ikey,
                    ip: response.data.ip,
                    maximumCamera: response.data.maximumCamera,
                    name: response.data.name,
                    status: response.data.status == "Running" ? this.$t('root.active') : this.$t('root.stopWorking'),
                }
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },

        saveChange() {
            let aiServiceDTO = {
                apiPort: this.serviceInfo.apiPort,
                domain: this.serviceInfo.domain,
                ip: this.serviceInfo.ip,
                maximumCamera: new Number(this.serviceInfo.maximumCamera),
                name: this.serviceInfo.name,
                status: this.serviceInfo.status.value,
                token_key: ''
            }
            this.$vs.loading();
            this.$crm.post(`ai-service/update/${this.$route.query.id}`, aiServiceDTO).then(() => {
                this.$vs.notify({
                    text: this.$t('root.updateAI'),
                    color: 'success',
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
                this.$vs.loading.close();
                this.$router.push(`/admin/camera-serviceAI`);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                })
            })
        },

        goBack() {
            this.$router.push('/admin/camera-serviceAI').catch(() => { })
        }
    }
}
</script>